import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>NOT FOUND</h1>
      <p>Sorry, but the page you're looking for is not available.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
